.headline {
  color: #26252c;
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin-top: 30px;
}

.subtitle {
  color: #707187;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-top: -10px;
}

@media screen and (max-width: 500px) {
  .loginfom,
  .subtitle,
  .form,
  .icon,
  .loginbtn,
  .bottom,
  .headline,
  .head,
  .emailinput,
  .icon,
  .otherOption,
  .horizontalLine {
    width: 70%;
  }
}
