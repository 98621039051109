.loginfom {
  margin-left: 20px;
  margin-top: -20px;
}

.subtitle {
  color: #707187;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-top: -10px;
}

.form {
  margin-top: 30px;
}

.icon {
  color: grey;
}

.loginbtn {
  margin-top: 20px;
}

.bottom {
  margin-top: 15px;
}

.headline {
  color: #26252c;
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin-top: 48px;
}

.head {
  display: flex;
  width: 594px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1px;
  flex-shrink: 0;
}

.emailinput {
  display: flex;
  padding: 8px 0px;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  border-bottom: 1px solid var #d8d8df;
  background: #fff;
}

.icon {
  margin-bottom: 10px;
  width: 27px;
  height: 27px;
}

.otherOption{
  display: flex;
  margin-left: -10px;
}

.horizontalLine{
  position: relative;
  left: 110px;
  top: -24px;
  width: 730px;

}

@media screen and (max-width: 500px) {
 
            .loginfom,
            .subtitle,
            .form,
            .icon,
             .loginbtn,
            .bottom,
             .headline,
            .head,
             .emailinput,
             .icon,
             .otherOption,
             .horizontalLine{
                width: 70%;
            }
        }
