.sidebar{
    background-color: white;
    width: 500px;
    /* margin-left: 32px; */
    height: 146vh;
    /* margin-top: 16px; */
    margin: 16px 0px 0px 16px;
    /* overflow: hidden; */
    /* position: fixed; */
}

.list-items{
    margin-top: 20px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  /* background-color: #f1f1f1; */
  /* position: fixed; */
  height: 100%;
  overflow: auto;
}

li a {
  display: block;
  color: #000;
  padding: 8px 16px;
  text-decoration: none;
}

li a:hover:not(.active) {
  background-color: #555;
  color: white;
}
