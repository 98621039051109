.product-card-container {
  display: flex;
  overflow-x: auto;
  align-items: flex-start;
  gap: 16px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
  margin: 0;
  padding: 0;
}

.product-card-container::-webkit-scrollbar {
  display: none;
}

.product-card {
  background: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0;
  padding: 0;
}

.product-card:hover {
  background-color: #f0f0f0;
}

.product-card img {
  height: 148px;
  width: 100%;
}

.price-wishlist {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.price-wishlist div {
  display: flex;
  align-items: flex-start;
}

.product-price p,
.price-wishlist p {
  margin: 0;
  padding: 0;
  text-align: left;

  font-family: Work Sans;
  /* font-size: 16px !important; */
  font-style: normal;
  /* font-weight: 500 !important; */
  /* line-height: 110%; */
}

.productPrice-1 {
  color: #707187 !important;
  font-family: Work Sans;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 110%;
  text-decoration-line: line-through;
  margin-left: 6px !important;
}

.productPrice-2 {
  color: #26252c !important;
  font-family: "Work Sans";
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 110%;
  margin-left: 6px !important;
}

.wishlist img {
  width: 20px;
  height: 20px;
}

.product-name {
  margin-top: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}

.product-name p {
  margin: 0;
  padding: 0;
  text-align: left;

  overflow: hidden;
  color: #26252c;
  text-overflow: ellipsis;

  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 115%;
}

.state-pin {
  display: flex;
  align-items: center;
  height: 20px;
  margin-top: 6px;
}

.state-pin img {
  width: 13.58px;
  height: 13.58px;
  margin: 0;
}

.state-pin p {
  overflow: hidden;
  color: #707187;
  text-overflow: ellipsis;
  font-family: "Work Sans";
  font-size: 11.64px;
  font-style: normal;
  font-weight: 500;
  line-height: 115%;
  margin: 0;
}

.big-dot {
  width: 3.88px;
  height: 3.88px;
  background-color: #707187;
  border-radius: 50%;
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
}

.village-district p {
  margin: 0;
  margin-top: 4px;
  overflow: hidden;
  color: #707187;
  text-overflow: ellipsis;
  font-family: Work Sans;
  font-size: 11.64px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 115%;
}

.sponsored {
  margin: 0 !important;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px !important;
}

.sponsored div {
  background-color: #ecf1ff;
  border-radius: 4px;
}

.sponsored-text {
  margin: 0 !important;
  padding: 4px;
  color: #26252c !important;
  font-family: "Work Sans";
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 115%;
}

.sponsored-days {
  margin: 0;
  padding: 0;
  overflow: hidden;
  color: #707187;
  text-overflow: ellipsis;
  font-family: "Work Sans";
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 115%;
  align-items: center;
  flex-grow: 1;
  text-align: right;
}
