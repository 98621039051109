html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #f7f7f8;
}
/* 
.bgColor {
  height: 100vh;
  background: #f7f7f8;
} */
