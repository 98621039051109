.productslide-container {
  /* width: 1376px; */
  /* height: 390px; */
  /* flex-shrink: 0; */
  border-radius: 4px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #d8d8df;
  /* margin-top: 16px; */
  overflow: hidden;
}

/* .productslide-container p {
  margin: 0;
  padding: 0;
  margin: 16px;
  color: #000;
  font-family: "Work Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
} */
