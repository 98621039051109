.banner-slider {
  margin: 0 auto;
  margin-top: 24px;
  border-radius: 4px;
  cursor: pointer;
}

.slider-container {
  position: relative;
  overflow: hidden;
}

.slide {
  display: none;
  transition: opacity 1.5s ease-in-out;
}

.slide.active {
  display: block;
}

.slide img {
  width: 100%; 
}

.slide-image {
  height: 228px; 
  border-radius: 4px;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 1rem;
  z-index: 100;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.prev div{
  margin-left: -50px;
  left:0px;
  width: 112px;
  border-radius: 4px;
  background-color: #fff;
  transform: rotate(90deg);
}

.next div{
  margin-right: -50px;
  left:0px;
  width: 112px;
  border-radius: 4px;
  background-color: #fff;
  transform: rotate(-90deg);
}


