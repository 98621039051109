.wrapper {
    z-index: 2000;
    display: flex;
    align-items: center;
    position: fixed;
    padding: 0px 60px 0px 60px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    box-sizing: border-box;
  }
  
  .content {
    margin: auto;
    padding: 0;
    width: 90%;
    height: 100%;
    max-height: 100%;
    text-align: center;
  }
  
  .slide {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .image {
    max-height: 100%;
    max-width: 100%;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
  }
  
  .close {
    color: white;
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
    z-index: 1000;
  }
  

  .navigation {
    z-index: 1000;
    height: 80%;
    color: white;
    cursor: pointer;
    position: absolute;
    font-size: 60px;
    line-height: 60px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 0 15px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
  }
  
  .prev {
    left: 0;
  }
  
  .next {
    right: 0;
  }
  
  @media (max-width: 900px) {
    .wrapper {
      padding: 0;
    }
  }